var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', [_vm._v(" Stats "), _c('v-spacer'), _c('vx-input', {
    attrs: {
      "type": "date",
      "range": "",
      "hide-details": "",
      "label": "Date range"
    },
    on: {
      "change": function change($event) {
        return _vm.getCount();
      }
    },
    model: {
      value: _vm.dateRange,
      callback: function callback($$v) {
        _vm.dateRange = $$v;
      },
      expression: "dateRange"
    }
  })], 1), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-card-list', {
    staticClass: "flex-1-0",
    attrs: {
      "card-props": {
        outlined: true,
        class: 'w-100'
      },
      "title": "Basic numbers",
      "hide-edit": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-title', [_vm._v(" # of new users ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.count.users) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" # of new artist profiles ")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.count.artistAccounts) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" # of new host profiles ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.count.hostAccounts) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" # of new locations ")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.count.locations) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" # of new spaces ")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.count.spaces) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Total quoted/estimated for potential bookings ")]), _c('v-list-item-subtitle', [_vm._v("£" + _vm._s(_vm.count.bookingsTotalQuoted) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Total spend on bookings ")]), _c('v-list-item-subtitle', [_vm._v("£" + _vm._s(_vm.count.bookingsTotalSpend) + " ")])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-card-list', {
    staticClass: "flex-1-0",
    attrs: {
      "card-props": {
        outlined: true,
        class: 'w-100'
      },
      "title": "Booking funnel numbers",
      "hide-edit": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-title', [_vm._v(" # of messages sent ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.count.messages) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" # of booking requests sent ")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.count.bookingsSent) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" # of booking requests paid for/confirmed ")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.count.bookingsConfirmed) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" # of bookings occurred ")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.count.bookings) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" # of booking requests rejected ")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.count.bookingsRejected) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" # of bookings cancelled ")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.count.bookingsCancelled) + " ")])], 1)], 1)], 1)], 1)], 1)], 1), _vm.dev ? _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', [_vm._v("Integrations")]), _c('v-card-text', [_c('v-row', _vm._l(_vm.integrations, function (item) {
    return _c('v-col', {
      key: item.id,
      attrs: {
        "cols": "12",
        "sm": "2"
      }
    }, [_c('v-card', {
      attrs: {
        "flat": "",
        "target": "blank",
        "href": item.link.dashboard
      }
    }, [_c('vx-img', {
      attrs: {
        "square": "",
        "src": item.logo
      }
    }), _c('v-card-subtitle', {
      staticClass: "py-2 text-center"
    }, [_vm._v(_vm._s(item.title))]), _c('v-card-actions', {
      staticClass: "py-2 justify-center"
    }, [_c('vx-btn', {
      attrs: {
        "text": "",
        "x-small": "",
        "blank": "",
        "href": item.link.api
      }
    }, [_vm._v("API")]), _c('vx-btn', {
      attrs: {
        "text": "",
        "x-small": "",
        "blank": "",
        "href": item.link.docs
      }
    }, [_vm._v("Docs")])], 1)], 1)], 1);
  }), 1)], 1)], 1) : _vm._e(), false ? _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', [_vm._v("Libraries")]), _c('v-card-text', [_c('v-row', _vm._l(_vm.libraries, function (item) {
    return _c('v-col', {
      key: item.id,
      attrs: {
        "cols": "12",
        "sm": "2"
      }
    }, [_c('v-card', {
      attrs: {
        "flat": ""
      }
    }, [_c('vx-img', {
      attrs: {
        "square": "",
        "src": item.logo
      }
    }), _c('v-card-subtitle', {
      staticClass: "py-2 text-center"
    }, [_vm._v(_vm._s(item.title))]), _c('v-card-actions', {
      staticClass: "py-2 justify-center"
    }, [_c('vx-btn', {
      attrs: {
        "text": "",
        "x-small": "",
        "blank": "",
        "href": item.link.api
      }
    }, [_vm._v("API")]), _c('vx-btn', {
      attrs: {
        "text": "",
        "x-small": "",
        "blank": "",
        "href": item.link.docs
      }
    }, [_vm._v("Guide")])], 1)], 1)], 1);
  }), 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }