<template>
  <div>
    <v-card class="mt-4">
      <v-card-title>
        Stats
        <v-spacer />
        <vx-input v-model="dateRange" type="date" range hide-details label="Date range" @change="getCount()" />
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <vx-card-list
              :card-props="{ outlined: true, class: 'w-100' }"
              class="flex-1-0"
              title="Basic numbers"
              hide-edit
            >
              <v-list-item>
                <v-list-item-title> # of new users </v-list-item-title>
                <v-list-item-subtitle> {{ count.users }} </v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-title> # of new artist profiles </v-list-item-title>
                <v-list-item-subtitle>{{ count.artistAccounts }} </v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-title> # of new host profiles </v-list-item-title>
                <v-list-item-subtitle> {{ count.hostAccounts }} </v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-title> # of new locations </v-list-item-title>
                <v-list-item-subtitle>{{ count.locations }} </v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-title> # of new spaces </v-list-item-title>
                <v-list-item-subtitle>{{ count.spaces }} </v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-title> Total quoted/estimated for potential bookings </v-list-item-title>
                <v-list-item-subtitle>£{{ count.bookingsTotalQuoted }} </v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-title> Total spend on bookings </v-list-item-title>
                <v-list-item-subtitle>£{{ count.bookingsTotalSpend }} </v-list-item-subtitle>
              </v-list-item>
            </vx-card-list>
          </v-col>

          <v-col cols="12" sm="6">
            <vx-card-list
              :card-props="{ outlined: true, class: 'w-100' }"
              class="flex-1-0"
              title="Booking funnel numbers"
              hide-edit
            >
              <v-list-item>
                <v-list-item-title> # of messages sent </v-list-item-title>
                <v-list-item-subtitle> {{ count.messages }} </v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-title> # of booking requests sent </v-list-item-title>
                <v-list-item-subtitle>{{ count.bookingsSent }} </v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-title> # of booking requests paid for/confirmed </v-list-item-title>
                <v-list-item-subtitle>{{ count.bookingsConfirmed }} </v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-title> # of bookings occurred </v-list-item-title>
                <v-list-item-subtitle>{{ count.bookings }} </v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-title> # of booking requests rejected </v-list-item-title>
                <v-list-item-subtitle>{{ count.bookingsRejected }} </v-list-item-subtitle>
              </v-list-item>

              <v-list-item>
                <v-list-item-title> # of bookings cancelled </v-list-item-title>
                <v-list-item-subtitle>{{ count.bookingsCancelled }} </v-list-item-subtitle>
              </v-list-item>
            </vx-card-list>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card v-if="dev" class="mt-4">
      <v-card-title>Integrations</v-card-title>
      <v-card-text>
        <v-row>
          <v-col v-for="item in integrations" :key="item.id" cols="12" sm="2">
            <v-card flat target="blank" :href="item.link.dashboard">
              <vx-img square :src="item.logo" />

              <v-card-subtitle class="py-2 text-center">{{ item.title }}</v-card-subtitle>

              <v-card-actions class="py-2 justify-center">
                <vx-btn text x-small blank :href="item.link.api">API</vx-btn>
                <!-- <vx-btn text x-small blank :href="item.link.dashboard">Dashboard</vx-btn> -->
                <vx-btn text x-small blank :href="item.link.docs">Docs</vx-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- <v-subheader>Technologies</v-subheader>
    <v-row>
      <v-col cols="12" sm="2" v-for="item in technologies" :key="item.id">
        <v-card>
          <vx-img square :src="item.logo" />

          <v-card-subtitle>{{ item.title }}</v-card-subtitle>
        </v-card>
      </v-col>
    </v-row> -->

    <v-card v-if="false" class="mt-4">
      <v-card-title>Libraries</v-card-title>
      <v-card-text>
        <v-row>
          <v-col v-for="item in libraries" :key="item.id" cols="12" sm="2">
            <v-card flat>
              <vx-img square :src="item.logo" />

              <v-card-subtitle class="py-2 text-center">{{ item.title }}</v-card-subtitle>

              <v-card-actions class="py-2 justify-center">
                <vx-btn text x-small blank :href="item.link.api">API</vx-btn>
                <vx-btn text x-small blank :href="item.link.docs">Guide</vx-btn>
                <!-- <vx-btn text x-small>NPM</vx-btn> -->
                <!-- <vx-btn text x-small>Github</vx-btn> -->
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import _ from 'lodash';
import dayjs from 'dayjs';
import { CommonService } from '@tutti/services';

export default {
  data() {
    return {
      count: {},
      dateRange: [dayjs.utc().subtract(1, 'week').format('YYYY-MM-DD'), dayjs.utc().format('YYYY-MM-DD')],
      integrations: _.sortBy(
        [
          {
            title: 'ButterCMS',
            logo: 'https://avatars.githubusercontent.com/u/16144259?s=200&v=4',
            link: {
              docs: 'https://buttercms.com/docs/',
              api: 'https://buttercms.com/docs/api/',
              dashboard: 'https://buttercms.com/home/',
            },
          },
          {
            title: 'Crisp',
            logo: 'https://avatars.githubusercontent.com/u/16270189?s=200&v=4',
            link: {
              docs: 'https://docs.crisp.chat/api/v1/',
              api: 'https://docs.crisp.chat/api/v1/',
              dashboard: 'https://app.crisp.chat/website/8a0c2783-a029-4b14-a5fc-52936e37edb7/visitors',
            },
          },
          {
            title: 'Segment',
            logo: 'https://avatars.githubusercontent.com/u/819518?s=200&v=4',
            link: {
              docs: 'https://segment.com/docs/',
              api: 'https://reference.segmentapis.com/',
              dashboard: 'https://app.segment.com/tutti.space/overview',
            },
          },
          {
            title: 'Twilio',
            logo: 'https://avatars.githubusercontent.com/u/109142?s=200&v=4',
            link: {
              docs: 'https://www.twilio.com/docs',
              api: 'https://www.twilio.com/docs/api',
              dashboard: 'https://console.twilio.com/us1/?frameUrl=/console',
            },
          },
          {
            title: 'Voucherify',
            logo: 'https://avatars.githubusercontent.com/u/19346225?s=200&v=4',
            link: {
              docs: 'https://docs.voucherify.io/docs',
              api: 'https://docs.voucherify.io/reference',
              dashboard: 'https://us1.app.voucherify.io/#/app/core/dashboard',
            },
          },
          {
            title: 'What3words',
            logo: 'https://avatars.githubusercontent.com/u/10087988?s=200&v=4',
            link: {
              docs: 'https://developer.what3words.com/public-api/docs',
              api: 'https://developer.what3words.com/public-api',
              dashboard: 'https://developer.what3words.com/public-api/playground',
            },
          },
          {
            title: 'AWS',
            logo: 'https://avatars.githubusercontent.com/u/2232217?s=200&v=4',
            link: {
              docs: '',
              api: '',
              dashboard: 'https://eu-west-2.console.aws.amazon.com/console/home?region=eu-west-2#',
            },
          },
          {
            title: 'Ideal Postcodes',
            logo: 'https://avatars.githubusercontent.com/u/4996310?s=200&v=4',
            link: {
              docs: 'https://ideal-postcodes.co.uk/documentation',
              api: 'https://ideal-postcodes.co.uk/documentation/api',
              dashboard: 'https://ideal-postcodes.co.uk/tokens',
            },
          },
          {
            title: 'Google Maps',
            logo: 'https://avatars.githubusercontent.com/u/3717923?s=200&v=4',
            link: {
              docs: 'https://developers.google.com/maps/gmp-get-started',
              api: 'https://developers.google.com/maps/documentation/javascript/overview',
              dashboard:
                'https://console.cloud.google.com/google/maps-apis/overview?project=app-tutti-space&folder=&organizationId=',
            },
          },
          {
            title: 'ActiveCampaign',
            logo: 'https://avatars.githubusercontent.com/u/563440?s=200&v=4',
            link: {
              docs: 'https://developers.activecampaign.com/',
              api: 'https://developers.activecampaign.com/reference',
              dashboard: 'https://sixberries1583311953.activehosted.com/overview/',
            },
          },
          {
            title: 'Bugsnag',
            logo: 'https://avatars.githubusercontent.com/u/1058895?s=200&v=4',
            link: {
              docs: 'https://docs.bugsnag.com/',
              api: 'https://docs.bugsnag.com/api/',
              dashboard:
                'https://app.bugsnag.com/tutti-1/app-dot-tutti-dot-space/errors?filters[event.since][0]=30d&filters[error.status][0]=open&sort=last_seen',
            },
          },
        ],
        'title'
      ),
      technologies: [
        // {
        //   title: 'Segment',
        //   logo: '',
        //   link: {
        //     prod: 'https://app.segment.com/tutti.space/sources',
        //     test: '',
        //     dev: '',
        //   },
        // },
      ],
      libraries: _.sortBy(
        [
          {
            title: 'Vue',
            logo: 'https://avatars.githubusercontent.com/u/6128107?s=200&v=4',
            link: {
              api: '',
              docs: 'https://vuejs.org/v2/guide/',
            },
          },
          {
            title: 'Vuex',
            logo: 'https://avatars.githubusercontent.com/u/6128107?s=200&v=4',
            link: {
              api: 'https://vuex.vuejs.org/api/',
              docs: 'https://vuex.vuejs.org/guide/',
            },
          },
          {
            title: 'Vue CLI',
            logo: 'https://avatars.githubusercontent.com/u/6128107?s=200&v=4',
            link: {
              api: 'https://cli.vuejs.org/config/',
              docs: 'https://cli.vuejs.org/guide/',
            },
          },
          {
            title: 'Vuetify',
            logo: 'https://avatars.githubusercontent.com/u/22138497?s=200&v=4',
            link: {
              api: 'https://vuetifyjs.com/en/api/vuetify/',
              docs: 'https://vuetifyjs.com/en/getting-started/installation/',
            },
          },
          {
            title: 'Nuxt',
            logo: 'https://avatars.githubusercontent.com/u/23360933?s=200&v=4',
            link: {
              api: 'https://nuxtjs.org/docs/2.x/configuration-glossary/configuration-alias',
              docs: 'https://nuxtjs.org/docs/2.x/get-started/installation',
            },
          },
          {
            title: 'Lodash',
            logo: 'https://avatars.githubusercontent.com/u/2565403?s=200&v=4',
            link: {
              api: 'https://lodash.com/docs/4.17.15',
              docs: 'https://lodash.com/docs/4.17.15',
            },
          },
          {
            title: 'Moment',
            logo: 'https://avatars.githubusercontent.com/u/4129662?s=200&v=4',
            link: {
              api: 'https://momentjs.com/docs/',
              docs: 'https://momentjs.com/docs/',
            },
          },
        ],
        'title'
      ),
    };
  },
  mounted() {
    this.getCount();
  },
  methods: {
    getTimestamp() {
      return Date.now();
    },
    async getCount() {
      const response = await CommonService.count({ range: this.dateRange });
      if (response) {
        this.count = response.data;
      }
    },
  },
};
</script>
